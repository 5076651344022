import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/Layout"
import React from "react"
import ReactPlayer from 'react-player/lazy'
import Seo from "../components/seo"

const VideoPage = ({pageContext}) => {

  const {
    breadcrumb: { crumbs },
  } = pageContext;

  let crumbs_mod = crumbs.slice(0);

  crumbs_mod.splice(-1, 0, {pathname: '/media', crumbLabel: 'Media'});

  const YT_videos = [
  'https://www.youtube.com/watch?v=xT9LLfVwdms',
  'https://www.youtube.com/watch?v=ta_M-sC8WtU',
  'https://www.youtube.com/watch?v=RPKQ4xBJLfQ',
  'https://www.youtube.com/watch?v=ks0WiafOMOE',
  'https://www.youtube.com/watch?v=e4ZesT4FhR0',
  'https://www.youtube.com/watch?v=a5q9_AyJpsY',
  'https://www.youtube.com/watch?v=7MbERVtZ8sQ',
  'https://www.youtube.com/watch?v=IU9e1ho_AIs',
  'https://www.youtube.com/watch?v=Cd9m7Renbzk',
  'https://www.youtube.com/watch?v=UR9WXmnSNEQ',
  'https://www.youtube.com/watch?v=S2mEWGfuNMg',
  'https://www.youtube.com/watch?v=Fc4OxhJ05uo',
  'https://www.youtube.com/watch?v=o1K-L1R01yU',
  'https://www.youtube.com/watch?v=cZjUX-etLX4',
  'https://www.youtube.com/watch?v=uTJV0xWWPj4',
  ];

  return (
    <Layout >
      <Seo title="Talman videos" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">Talman<br /> videos</h1>
        <Breadcrumb crumbs={crumbs_mod} crumbLabel="Video" crumbSeparator="»" />
        
        <section className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-10 lg:mt-20">
          {YT_videos.map((yt_url) => {
            return <div className="aspect-w-16 aspect-h-9 border border-gray-475"><ReactPlayer width="100%" height="100%" url={yt_url} /></div>
          })}
        </section>
        
        <h3 className="text-xl text-center mt-20">more coming soon!</h3>
      </div>
    </Layout>
  )
}

export default VideoPage